import React from "react";
import { get } from "lodash-es";
import paths from "path";

import { hierarchize } from "./utils/hierarchical";

const keyName = "key";
const pathName = "path";
const uniqueKeyName = "uniqueKey";

function pathGenerator(node, parent) {
  const parentUniqueKey = get(parent, uniqueKeyName);
  const uniqueKey = parentUniqueKey
    ? parentUniqueKey + "." + node[keyName]
    : node[keyName];

  const parentPath = get(parent, pathName, "");
  const path = get(node, pathName, paths.join(parentPath, node[keyName]));
  node[uniqueKeyName] = uniqueKey;
  node[pathName] = path;
}

const routeConfig = hierarchize(
  {
    key: "dashboard",
    name: "Dashboard",
    path: "/",
    exact: true,
    component: React.lazy(() => import("./containers/Dashboard")),
    children: [
      {
        key: "login",
        name: "Login",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/Login")),
        validateRole: false,
      },
      {
        key: "share-link",
        name: "Share Link",
        exact: true,

        isPublic: true,
        component: React.lazy(() => import("./containers/ShareLink")),
        validateRole: false,
      },
      {
        key: "start",
        name: "Extension",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/Extension")),
        validateRole: false,
      },
      {
        key: "extension",
        name: "Extension",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/Login/LoginExtension")
        ),
        validateRole: false,
      },
      {
        key: "extension/:token",
        name: "Extension",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/Login/ExtensionRedirect")
        ),
        validateRole: false,
      },
      {
        key: "sign-up",
        name: "sign-up",
        isPublic: true,
        isHidden: true,
        exact: false,
        component: React.lazy(() => import("./containers/Auth/SignUp")),
        validateRole: false,
      },
      {
        key: "otpverification",
        name: "otpverification",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/UserVerification/index")
        ),
        validateRole: false,
      },
      {
        key: "emailverification",
        name: "emailverification",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/VerificationEmail")
        ),
        validateRole: false,
      },
      {
        key: "verification",
        name: "verification",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/UserVerification/Verification")
        ),
        validateRole: false,
      },
      {
        key: "userinfo",
        name: "userinfo",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() =>
          import("./containers/Auth/UserAccountInformation")
        ),
        validateRole: false,
      },
      {
        key: "forgot-password",
        name: "Forgot Password",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/ForgotPassword")),
        validateRole: false,
      },
      {
        key: "forgot-password-info",
        name: "Forgot Password Info",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/ForgotPasswordInformation")),
        validateRole: false,
      },
      {
        key: "reset-password/:resetPasswordToken",
        name: "Reset Password",
        isPublic: true,
        isHidden: true,
        exact: true,
        component: React.lazy(() => import("./containers/Auth/ResetPassword")),
        validateRole: false,
      },
      {
        key: "dashboard",
        name: "Dashboard",
        exact: true,
        isPublic: true,
        component: React.lazy(() => import("./containers/Dashboard")),
        validateRole: false,
      },
      {
        key: "account",
        name: "Account",
        isHidden: true,
        component: React.lazy(() => import("./containers/Account")),
        validateRole: false,
      },
      {
        key: "orders",
        name: "Orders",
        isHidden: true,
        component: React.lazy(() => import("./containers/Orders")),
        validateRole: false,
      },
      {
        key: "balance",
        name: "Balance",
        isHidden: true,
        component: React.lazy(() => import("./containers/Balance")),
        validateRole: false,
      },
      {
        key: "users",
        name: "Users",
        component: React.lazy(() => import("./containers/Users")),
        validateRole: false,
      },
      {
        key: "explore",
        name: "Explore",
        component: React.lazy(() => import("./containers/Explore")),
        validateRole: false,
      },
      // {
      //   key: "explore-list",
      //   name: "ExploreList",
      //   component: React.lazy(() =>
      //     import("./partials/Explore/ExploreList/index")
      //   ),
      //   validateRole: false,
      // },
      {
        key: "wardrobe",
        name: "Wardrobe",
        component: React.lazy(() => import("./containers/Wardrobe")),
        validateRole: false,
      },
      {
        key: "your-lists",
        name: "Your Lists",
        component: React.lazy(() => import("./containers/YourLists")),
        validateRole: false,
      },
      {
        key: "list-items",
        name: "List Item",
        component: React.lazy(() =>
          import("./partials/YourLists/ListItems/index")
        ),
        validateRole: false,
      },
      {
        key: "wishlist",
        name: "Wishlist",
        component: React.lazy(() =>
          import("./partials/YourLists/Wishlist/index")
        ),
        validateRole: false,
      },
      {
        key: "giftlist",
        name: "Gift List",
        path: "/giftlist/:userId?",
        component: React.lazy(() =>
          import("./partials/YourLists/GiftList/index")
        ),
        validateRole: false,
      },
      {
        key: "people-profile/:userId",
        name: "People Profile Details",
        isPublic: true,
        component: React.lazy(() =>
          import("./partials/PeopleProfile/PeopleYouFollow")
        ),
        validateRole: false,
      },
      {
        key: "people-profile",
        name: "People Profile",
        component: React.lazy(() => import("./containers/People")),
        validateRole: false,
      },

      {
        key: "people/:userId/user-list/:listId",
        name: "People Profile",
        isPublic: true,
        component: React.lazy(() =>
          import("./partials/PeopleProfile/PeopleListDetails")
        ),
        validateRole: false,
      },
      {
        key: "search-results",
        name: "Search Results",
        component: React.lazy(() => import("./partials/SearchResults/index")),
        validateRole: false,
      },
    ],
  },
  null,
  pathGenerator
);

export default routeConfig;
