function EyeClosedIcon({ stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="19"
      fill="none"
      viewBox="0 0 24 19"
      className="with-hover-effect white"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M16.142 8.946c.024.243.024.487 0 .73a3.787 3.787 0 01-4.085 3.467M8.942 11.133a3.733 3.733 0 01-.405-1.91 3.787 3.787 0 015.487-3.2M20.179 4.653A11.2 11.2 0 0123 9.079M1.64 9.565a11.2 11.2 0 0115.798-6.87M8.243 16.748A11.2 11.2 0 0023 9.575M1.641 9.794a11.2 11.2 0 003.424 5.066M19.31 1.496L4.377 16.962"
      ></path>
    </svg>
  );
}

export default EyeClosedIcon;
