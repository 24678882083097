function AvatarPlaceholderIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="81"
      height="90"
      fill="none"
      viewBox="0 0 81 90"
    >
      <path
        stroke="#A6A6A6"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="5"
        d="M3 87.416a38.883 38.883 0 0175-1.668M42.37 41.66H38.61a17.774 17.774 0 01-17.774-17.775v-1.627A19.259 19.259 0 0140.093 3a20.051 20.051 0 0120.072 20.072v.813A17.774 17.774 0 0142.391 41.66h-.02z"
      ></path>
    </svg>
  );
}

export default AvatarPlaceholderIcon;
