import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
// import cheekyblacklogo from "../../assets/images/cheekyblacklogo.svg";

const Page = forwardRef(
  (
    {
      children,
      title = "",
      containerFluid = false,
      padding,
      className,
      ...rest
    },
    ref
  ) => {
    const showRoom = useSelector((state) => state.user.wardrobeRoom);
    return (
      <main
        ref={ref}
        {...rest}
        style={{
          marginLeft: showRoom && "150px",
          width: showRoom && "calc(100% - 150px)",
        }}
      >
        <Helmet>
          <title>{title ? `${title} | ` : ""}Cheeky</title>
        </Helmet>
        <div
          // fluid={containerFluid}
          className={`${className}`}
        >
          {/* <div className="sticky-drawer">
          <img src={cheekyblacklogo} alt="logo" />
        </div> */}
          {children}
        </div>
      </main>
    );
  }
);

Page.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
  containerFluid: PropTypes.bool,
  padding: PropTypes.bool,
  className: PropTypes.string,
};

Page.defaultProps = {
  containerFluid: false,
  padding: true,
  className: "",
};

export default Page;
