function ArrowDownIcon({ fill = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill={fill}
        d="M2.904 4L7.8 8.945 12.696 4 14.2 5.522 7.8 12 1.4 5.522 2.904 4z"
      ></path>
    </svg>
  );
}

export default ArrowDownIcon;
