function LinkIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 18 18"
      cursor="pointer"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="1.5"
        d="M17 1L7 11M17 1v6m0-6h-6M7 1H1v16h16v-6"
      ></path>
    </svg>
  );
}

export default LinkIcon;
