import avatar_placeholder from "../../assets/images/avatar-gradient.png";
import "./styles.scss";

const Avatar = ({ size = "25px", src = avatar_placeholder }) => {
  return (
    <div className="avatar-wrapper" style={{ height: size, width: size }}>
      <img src={src} alt="avatar" className="avatar-image" />
    </div>
  );
};

export default Avatar;
