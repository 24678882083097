function UncheckedCheckboxIcon({ fill = "#000" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      fill="none"
      viewBox="0 0 18 18"
      cursor="pointer"
    >
      <path
        fill={fill}
        d="M16 2v14H2V2h14zm0-2H2C.9 0 0 .9 0 2v14c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V2c0-1.1-.9-2-2-2z"
      ></path>
    </svg>
  );
}

export default UncheckedCheckboxIcon;
