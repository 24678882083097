function CheekyLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="108"
      height="25"
      fill="none"
      viewBox="0 0 108 25"
    >
      <path
        fill="#fff"
        d="M89.964 24.49l.255-4.44c.32.08.67.155 1.05.225.39.07.81.105 1.26.105.7 0 1.224-.12 1.574-.36.36-.23.665-.775.915-1.635l-.315-.06-5.19-14.368h6.975l1.83 5.954c.06.27.115.545.165.825.06.27.105.545.135.825h.09c.02-.28.05-.555.09-.825.05-.28.115-.55.195-.81l1.89-5.97h6.164l-4.814 14.04c-.85 2.68-1.885 4.514-3.105 5.504-1.22 1-2.825 1.5-4.815 1.5-.97 0-1.84-.05-2.61-.15-.77-.09-1.35-.21-1.74-.36z"
      ></path>
      <path
        fill="#fff"
        d="M71.603 18.43V0h6.885l.03 7.271 3.015-3.33h7.53l-10.53 11.685v2.804h-6.93zm10.5 0l-3.615-6.239 4.185-5.25 7.169 11.49h-7.74zM53.635 11.38v-.63c0-2.3.78-4.089 2.34-5.369 1.56-1.29 3.634-1.935 6.224-1.935 2.64 0 4.655.66 6.045 1.98 1.4 1.32 2.09 3.13 2.07 5.43v1.125H57.608v-1.785h6.12v-.3c0-.56-.11-.995-.33-1.305-.22-.32-.65-.48-1.29-.48-.54 0-.945.17-1.215.51-.27.34-.405.87-.405 1.59v1.77c0 .73.15 1.264.45 1.604.3.34.76.51 1.38.51.45 0 .84-.115 1.17-.345.34-.24.595-.555.765-.944l6.03 1.17c-.4 1.57-1.246 2.774-2.535 3.614-1.29.83-3.08 1.245-5.37 1.245-2.73 0-4.87-.64-6.42-1.92-1.55-1.29-2.324-3.134-2.324-5.534zM35.938 11.38v-.63c0-2.299.78-4.089 2.34-5.369 1.56-1.29 3.634-1.934 6.224-1.934 2.64 0 4.654.66 6.044 1.98 1.4 1.32 2.09 3.13 2.07 5.429v1.125H39.912v-1.785h6.12v-.3c0-.56-.11-.995-.33-1.305-.22-.32-.65-.48-1.29-.48-.54 0-.945.17-1.215.51-.27.34-.405.87-.405 1.59v1.77c0 .73.15 1.265.45 1.605.3.34.76.51 1.38.51.45 0 .84-.115 1.17-.345.34-.24.595-.555.765-.945l6.03 1.17c-.4 1.57-1.246 2.774-2.536 3.614-1.29.83-3.08 1.245-5.37 1.245-2.729 0-4.869-.64-6.419-1.92-1.55-1.29-2.325-3.134-2.325-5.534zM17.94 18.43V0h6.914v5.907c.28-.55.78-1.075 1.5-1.575.73-.5 1.79-.75 3.18-.75 1.45 0 2.654.395 3.614 1.185.96.78 1.44 2.01 1.44 3.69v9.973h-6.944V9.836c0-.45-.13-.78-.39-.99-.26-.22-.515-.33-.765-.33-.45 0-.805.135-1.065.405-.26.26-.44.555-.54.885v8.624h-6.945zM0 11.38v-.63c0-2.369.77-4.179 2.31-5.429 1.55-1.25 3.625-1.874 6.224-1.874 2.62 0 4.595.55 5.925 1.65 1.34 1.1 2.085 2.65 2.235 4.649l.03.555h-6.81v-.555c0-.43-.11-.765-.33-1.005-.22-.25-.57-.375-1.05-.375-.53 0-.925.15-1.185.45-.25.3-.375.75-.375 1.35v1.935c0 .64.12 1.115.36 1.425.25.31.65.465 1.2.465.51 0 .865-.125 1.065-.375.21-.25.315-.57.315-.96v-.585h6.81l-.015.51c-.05 1.96-.77 3.49-2.16 4.59-1.39 1.1-3.38 1.65-5.97 1.65-2.6 0-4.68-.636-6.24-1.906C.78 15.645 0 13.801 0 11.381z"
      ></path>
    </svg>
  );
}

export default CheekyLogoIcon;
