function ScreenIcon({ fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23 1H1v16h22V1zM12 17v6M6.5 23h11"
      ></path>
    </svg>
  );
}

export default ScreenIcon;
