function SearchIcon({ stroke = "#B8B8B8", size = "20px" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8.522 16.044A7.522 7.522 0 108.522 1a7.522 7.522 0 000 15.044z"
      ></path>
      <path
        stroke="#B8B8B8"
        strokeLinecap="round"
        strokeMiterlimit="10"
        strokeWidth="2"
        d="M13.832 13.832L19 19"
      ></path>
    </svg>
  );
}

export default SearchIcon;
