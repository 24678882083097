function BellIcon({ height = "25px", width = "20px", fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 22 27"
    >
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.144 24.235c-.312.537-.76.982-1.3 1.292a3.566 3.566 0 01-3.55 0 3.538 3.538 0 01-1.299-1.292M21 20.228H1a13.668 13.668 0 001.4-6.87l-.114-1.884c-.15-1.95.246-3.902 1.143-5.643C5.01 2.907 7.817 1 10.97 1c3.155 0 5.96 1.907 7.543 4.831a10.557 10.557 0 011.143 5.643l-.108 1.828a13.825 13.825 0 001.394 6.926H21z"
      ></path>
    </svg>
  );
}

export default BellIcon;
