import Sidebar from "../../components/Sidebar";

const MainBlock = ({ children }) => {
  return (
    <div className="mt-1">
      <Sidebar />
      {children}
    </div>
  );
};

export default MainBlock;
