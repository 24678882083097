import { Divider, Menu, MenuItem } from "@mui/material";
import PropTypes from "prop-types";
import React, { useState } from "react";

const DropdownMenu = ({ menu, style, className, icon, iconSize, name }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event) => {
    event.stopPropagation();
    event.preventDefault();

    if (!menu.length) return false;

    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event) => {
    event.stopPropagation();
    event.preventDefault();
    setAnchorEl(null);
  };

  const renderMenuItem = (item, index) => {
    if (item.divider) return <Divider />;

    const elProps = {
      key: `menu-item-${index}`,
      disableRipple: true,
    };

    if (!item.containerElement && item.onClick) {
      elProps.onClick = item.onClick;
    }

    return (
      <MenuItem
        className="dropdown-menuitem"
        {...elProps}
        disabled={item.disabled || false}
      >
        <div
          className={`dropdown-menuitem-items ${
            item?.className && `${item.className}`
          }`}
        >
          {item.icon ? <>{item?.icon}</> : null}
          <span className="item-name">{item.label}</span>
        </div>
      </MenuItem>
    );
  };

  return menu ? (
    <>
      <button
        onClick={handleClick}
        className="dropdown-menu-button"
        style={{ ...style.button }}
      >
        {icon}
        {name}
      </button>

      <Menu
        anchorEl={anchorEl}
        open={open}
        className={className}
        onClose={handleClose}
        onClick={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            fontSize: "16px",
            boxShadow: "0px 5px 25px 2px rgba(255, 255, 255, 0.1)",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            color: "#000",
            background: "#fff",
            minWidth: "161px",
            ...style.menu,
          },
        }}
      >
        {menu && menu.map((item, index) => renderMenuItem(item, index))}
      </Menu>
    </>
  ) : null;
};

DropdownMenu.propTypes = {
  menu: PropTypes.array.isRequired,
  style: PropTypes.object,
  icon: PropTypes.any,
  iconSize: PropTypes.oneOf(["", "small", "large"]),
};

DropdownMenu.defaultProps = {
  style: {},
  iconSize: "",
  className: "",
};

export default DropdownMenu;
