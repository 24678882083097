function ShareIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="29"
      fill="none"
      viewBox="0 0 32 29"
    >
      <path
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M31 13.423L18.04 1v7.498h-2.999A14.008 14.008 0 001 22.514v5.427l3.647-4.047a18.685 18.685 0 0113.394-6.203v8.154L31 13.423z"
      ></path>
    </svg>
  );
}

export default ShareIcon;
