function ResetIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 16 16"
    >
      <path
        fill="#fff"
        d="M1.333 1.333l1.96 1.96A6.63 6.63 0 001.333 8 6.674 6.674 0 008 14.667 6.674 6.674 0 0014.667 8 6.674 6.674 0 008 1.333v1.334A5.34 5.34 0 0113.333 8 5.34 5.34 0 018 13.334 5.34 5.34 0 012.667 8c0-1.47.6-2.802 1.566-3.767L6 6V1.333H1.333z"
      ></path>
    </svg>
  );
}

export default ResetIcon;
