function ListsIcon({ size = "24px", stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 17.5L12 23l11-5.5M1 12l11 5.5L23 12M12 1L1 6.5 12 12l11-5.5L12 1z"
      ></path>
    </svg>
  );
}

export default ListsIcon;
