function CompassFilledIcon({ size = "24px" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      ></path>
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.897 17.835l7.8-4.196 4.195-7.841-7.8 4.2-4.195 7.837z"
      ></path>
    </svg>
  );
}

export default CompassFilledIcon;
