function ProfileIcon({ size = "22px", fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 23c6.075 0 11-4.925 11-11S18.075 1 12 1 1 5.925 1 12s4.925 11 11 11z"
      ></path>
      <path
        fill={fill}
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M14.964 9.71c0 1.63-1.32 3.474-2.949 3.474-1.629 0-2.949-1.844-2.949-3.473 0-1.63 1.32-2.425 2.95-2.425 1.629 0 2.948.785 2.948 2.425z"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M5.82 21.167a6.636 6.636 0 0112.802-.288"
      ></path>
    </svg>
  );
}

export default ProfileIcon;
