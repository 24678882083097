import { styled } from "@mui/material";
import { MaterialDesignContent } from "notistack";

export const BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const IMAGE_BASE_URL = process.env.REACT_APP_S3_URL;
export const DEFAULT_PROFILE_IMAGE =
  "https://cdn.dribbble.com/users/45782/screenshots/11304218/media/314bfdb8d30e516a763ae5b33e173f9e.jpg?resize=320x240&vertical=center";

export const ENDPOINTS = {
  // Auth
  LOGIN: "auth/login",
  SOCIAL_LOGIN: "auth/social-login",
  SOCIAL_SIGNUP: "auth/social-login",
  SIGNUP: "auth/register",
  EMAILEXIST: "auth/is-account-exist",
  USERNAME_EXIST: "auth/is-account-exist",
  RESEND_OTP: "auth/resend-otp",
  VERIFY_OTP: "auth/verify-otp",
  VERIFY_ADMIN_SESSION: "auth/verify-admin-session",
  VERIFY_ADMIN_SESSION_OTP: "auth/verify-session-otp",
  REFRESH_TOKEN: "auth/refresh-token",
  FORGOT_PASSWORD: "auth/forgot-password",
  VERIFY_EMAIL: "auth/verify-email",
  RESET_PASSWORD_VERIFY: "auth/reset-password-verify",
  RESET_PASSWORD: "auth/reset-password",
  GET_PROFILE: "profile",
  UPLOAD_PROFILE_IMAGE: "profile/upload",
  LOGOUT: "auth/logout",

  // ACCOUNT
  UPDATE_PROFILE: "profile",
  UPDATE_PASSWORD: "account/change-password",
  SELECT_CATEGORY_ITEMS: "profile/categories",
  DEACTIVATE_PROFILE: "profile/deactivate-account",
  DELETE_PROFILE: "profile/delete-account",
  CHANGE_PROFILE_PASSWORD: "profile/change-password",
  SEARCH_USER_BY_ID: (id) => `profile/user/${id}`,
  SEARCH_USER_BY_NAME: "profile/search",
  FOLLOW_UNFOLLOW_USER: "profile/follow-or-unfollow",
  VERIFY_MOBILE_OTP: "profile/mobile-verification-otp",
  VERIFY_MOBILE: "profile/mobile-verification",
  USER_MAILS: "mail-service",

  //user
  ONBOARDING_INTERESTS: "auth/categories",
  BRANDS: "auth/brands",
  UPDATE_BRANDS: "profile/brands",
  CATEGORY_ITEMS_SELECTED: "profile/category-item",
  FETCH_USER_PROFILE: "profile",
  GET_YOUR_LISTS: "list",
  CREATE_YOUR_LISTS: "list",
  DELETE_YOUR_LISTS: (id) => `list/${id}`,
  UPDATE_YOUR_LISTS: (id) => `list/${id}`,
  GET_ITEM_DETAILS: (id) => `list/${id}`,
  UPDATE_LIST_ACCESS: (id) => `list/${id}/list-access`,
  DUPLICATE_LIST: `list/duplicate`,
  LIKE_LIST: "list/like",
  GET_NOTIFICATION_SETTING: "profile/notification-setting",
  UPDATE_NOTIFICATION_SETTING: "profile/notification-setting",
  FETCH_USER_OUTFIT_LISTS: "wardrobe",
  MARK_ITEM_PURCHASED: '/product/mark-item-purchased',
  FETCH_GIFT_LIST: '/product/giftlist',
  ADD_TO_GIFT_LIST: '/product/giftlist/add',
  REMOVE_FROM_GIFT_LIST: '/product/giftlist/remove',

  // Fetch Earning
  GET_USER_EARNING: "profile/transaction",

  // Fetch Referral
  GET_USER_REFERRAL: "profile/referral-detail",

  //Product
  GET_ALL_PRODUCT: "product",
  GET_PRODUCT_DETAILS: (id) => `product/${id}`,
  ADD_PRODUCT: "product",
  ADD_PRODUCTS: "product/add-to-cart",
  SIMILAR_PRODUCT: "product/similar",
  FETCH_SAVED_PRODUCT: "profile/saved-products",
  FETCH_SAVED_PRODUCT_PURCHASE_HISTORY: "product/purchase-history",
  POST_SAVED_PRODUCT_PURCHASE_HISTORY: "product/purchase-history",
  REMOVE_SAVED_PRODUCT_PURCHASE_HISTORY: "product/remove-purchase-history",
  ADD_PRODUCT_TO_ANOTHER_LIST: "list/add-to-another-list",
  LIKE_PRODUCT: "product/like",
  DELETE_PRODUCT: "product/delete",
  UPDATE_ORDER_TRACK_DETAILS: (id) => `product/${id}/tracking`,
  GET_PRODUCTS_BY_CATEGORIES: "/product/categories",

  WARDROBE_CREATE: "wardrobe",
  DELETE_WARDROBE_OUTFIT: (id) => `wardrobe/${id}`,
  WARDROBE_CREATE_BY_ID: (id) => `wardrobe/${id}`,
  WARDROBE_IMAGE_UPLOAD: "wardrobe/upload",
  WARDROBE_ITEM_UPDATE: (id) => `wardrobe/${id}`,
  WARDROBE_PUBLIC_DETAILS: (id) => `wardrobe/public/${id}`,
  DELETE_WARDROBE_ITEM_BYID_OUTFIT: (id, itemId) =>
    `wardrobe/${id}/item/${itemId}`,
  PRODUCT_TAGS: "product/tags",

  // Explore
  EXPLORE_TAGS: "explore/tag-list",
  GET_FOLLOW_USER_PUBLIC_LIST: "explore/follow-user-public-list",
  GET_FOLLOW_USER_PUBLIC_LIST_GUEST: "explore/follow-user-public-list-id",
  FETCH_TOP_BRANDS_USER: "explore/top-brand",
  FETCH_TOP_BRANDS_GUEST: "explore/top-brand-guest",
  FETCH_RECOMMENDED_BRANDS: "explore/recommended-brands",
  FETCH_FOR_YOU_USER: "explore/for-you",
  FETCH_FOR_YOU_GUEST: "explore/for-you-guest",
  GET_LIST_RECOMMENDATIONS: "explore/list-recommendations",
  GET_PROFILE_RECOMMENDATIONS: "explore/profile-recommendations", 
  FETCH_RECOMMENDATIONS: "explore/recommendations",
};

export const StyledMaterialDesignContent = styled(MaterialDesignContent)(
  () => ({
    "&.notistack-MuiContent-success": {
      fontSize: "18px",
      padding: "8px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      background: "#FFF500",
    },
    "&.notistack-MuiContent-error": {
      fontSize: "18px",
      padding: "8px 16px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: "black",
      background: "#FF2E00",
    },
  })
);
