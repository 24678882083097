function LockIcon({ fill, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="32"
      fill="none"
      viewBox="0 0 24 32"
    >
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23 15.903H1v14.193h22V15.903z"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M4.903 15.903V8.097A7.097 7.097 0 0112 1v0a7.097 7.097 0 017.097 7.097v7.806"
      ></path>
    </svg>
  );
}

export default LockIcon;
