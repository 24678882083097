import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { isValidPhoneNumber } from "libphonenumber-js";
import {
  isAlphaNumeric,
  isNumberAndSpecialCharacter,
  isValidEmail,
  passwordValidation,
} from "../utils";
import Constants from "../utils/constants";

const mobileRegExp = /^([0|+[0-9]{1,5})?([7-9][0-9]{9})$/;

export const loginRules = yupResolver(
  Yup.object({
    email: Yup.string()
      .required("Email,Username or Phone Number,  is a required field")
      .test(
        "is-mobile",
        "Username can be alphanumeric",
        function (value, { createError }) {
          if (isValidEmail(value) || isAlphaNumeric(value)) {
            return true;
          } else if (
            isValidPhoneNumber(value, Constants.COUNTRY_CODE) === true
          ) {
            return true;
          }
          if (isNumberAndSpecialCharacter(value)) {
            return createError({ message: "Provide valid US phone number" });
          }

          if (value.includes("@")) {
            return createError({ message: "Please provide valid email" });
          }
        }
      ),

    password: Yup.string().required("password is a required field"),
  }).required()
);

export const forgotPasswordInformationRules = yupResolver(
  Yup.object({
    email: Yup.string()
      .required("Email,Username or Phone Number,  is a required field")
      .test(
        "is-mobile",
        "Username can be alphanumeric",
        function (value, { createError }) {
          if (isValidEmail(value) || isAlphaNumeric(value)) {
            return true;
          } else if (
            isValidPhoneNumber(value, Constants.COUNTRY_CODE) === true
          ) {
            return true;
          }
          if (isNumberAndSpecialCharacter(value)) {
            return createError({ message: "Provide valid US phone number" });
          }

          if (value.includes("@")) {
            return createError({ message: "Please provide valid email" });
          }
        }
      ),
  }).required()
);

export const emailExistRules = yupResolver(
  Yup.object({
    email: Yup.string()
      .required("Email,Username or Phone Number,  is a required field")
      .test(
        "is-mobile",
        "Username can be alphanumeric",
        function (value, { createError }) {
          if (isValidEmail(value) || isAlphaNumeric(value)) {
            return true;
          } else if (
            isValidPhoneNumber(value, Constants.COUNTRY_CODE) === true
          ) {
            return true;
          }
          if (isNumberAndSpecialCharacter(value)) {
            return createError({ message: "Provide valid US phone number" });
          }

          if (value.includes("@")) {
            return createError({ message: "Please provide valid email" });
          }
        }
      ),
  }).required()
);
export const signupRules = yupResolver(
  Yup.object({
    email: Yup.string()
      .required("Email or Phone Number is a required field")
      .test(
        "is-mobile",
        "Not Valid Email or Valid US Mobile number",
        function (value, { createError }) {
          if (isValidEmail(value)) {
            return true;
          }
          return isValidPhoneNumber(value, Constants.COUNTRY_CODE) === true;
        }
      ),
    password: Yup.string()
      .required()
      .test({
        name: "password-validation",
        test: function (value) {
          const { path, createError } = this;

          return passwordValidation(value, createError, path);
        },
      }),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  }).required()
);

export const securitySettings = yupResolver(
  Yup.object({
    password: Yup.string().required(),
    newPassword: Yup.string()
      .required()
      .test({
        name: "password-validation",
        test: function (value) {
          const { path, createError } = this;

          return passwordValidation(value, createError, path);
        },
      }),
    confirmNewPassword: Yup.string().test(
      "New password-match",
      "New password must match",
      function (value) {
        return this.parent.newPassword === value;
      }
    ),
  }).required()
);

export const userListRules = yupResolver(
  Yup.object({
    title: Yup.string().required("Title is a required field"),
    tags: Yup.array()
      .of(
        Yup.object().shape({
          label: Yup.string().required("Required"),
          value: Yup.string().required("Required"),
        })
      )
      // .length(1)
      .min(0)
      .required("Required"),
  }).required("Required")
);

export const verifyLoginOTPRules = yupResolver(
  Yup.object({
    otp: Yup.string()
      .required("Please provide OTP")
      .length(6, "Should be 6 number OTP"),
  }).required()
);

export const verifyAccountStep1 = yupResolver(
  Yup.object({
    username: Yup.string()
      .required("Username is a required field")
      .test(
        "is-username",
        "Username can be alphanumeric",
        function (value, { createError }) {
          if (isAlphaNumeric(value)) {
            return true;
          }
          return false;
        }
      ),
  }).required()
);
export const verifyAccountStep2 = yupResolver(
  Yup.object({
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    gender: Yup.object().shape({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
    dob: Yup.date().typeError("Date of birth is a required field"),

    // language: Yup.object().shape({
    //   label: Yup.string().required("Required"),
    //   value: Yup.string().required("Required"),
    // }),
  }).required()
);

export const deactivateAccount = yupResolver(
  Yup.object({
    password: Yup.string().required(),
    reason: Yup.object().shape({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
  }).required()
);

export const deleteAccount = yupResolver(
  Yup.object({
    password: Yup.string().required(),
    reason: Yup.object().shape({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
  }).required()
);
export const verifyAdminSession = yupResolver(
  Yup.object({
    password: Yup.string().required(),
  }).required()
);

export const verifyAdminSessionOtp = yupResolver(
  Yup.object({
    passcode: Yup.number().typeError("Passcode is required.").required(),
  }).required()
);

export const inviteRules = yupResolver(
  Yup.object({
    firstName: Yup.string().required(),
    lastName: Yup.string().required(),
    countryCode: Yup.string().required(),
    mobile: Yup.string().test(
      "is-mobile",
      "Mobile number is not valid",
      function (value) {
        if (!value) return true;
        return mobileRegExp.test(value);
      }
    ),
    password: Yup.string().required(),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  })
);

export const addMemberRules = yupResolver(
  Yup.object({
    email: Yup.string().email().required(),
    role: Yup.string().required(),
  }).required()
);

export const createRoleRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    description: Yup.string().required(),
    isSuperAdmin: Yup.boolean(),
  }).required()
);

export const resetPasswordRules = yupResolver(
  Yup.object({
    password: Yup.string()
      .required()
      .test({
        name: "password-validation",
        test: function (value) {
          const { path, createError } = this;

          return passwordValidation(value, createError, path);
        },
      }),
    confirmPassword: Yup.string().test(
      "passwords-match",
      "Passwords must match",
      function (value) {
        return this.parent.password === value;
      }
    ),
  })
);

export const addCountryRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    isoCode: Yup.string().required(),
    financialStartDate: Yup.string().required(),
    financialQuarterOne: Yup.string().required(),
    financialQuarterTwo: Yup.string().required(),
    financialQuarterThree: Yup.string().required(),
    financialQuarterFour: Yup.string().required(),
  }).required()
);

export const addBucketRules = yupResolver(
  Yup.object({
    name: Yup.string(),
    dateFormat: Yup.string().required(),
    distanceUnit: Yup.string().required(),
  }).required()
);

export const addStateRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    isoCode: Yup.string().required(),
    countryCode: Yup.string().required(),
  }).required()
);

export const addCityRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    countryCode: Yup.string().required(),
    stateCode: Yup.string().required(),
  }).required()
);

export const addTagRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
  }).required()
);

export const addPlanIOSRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    amount: Yup.number()
      .typeError("Plan Price is required.")
      .required("Plan Price is required.")
      .test(
        "maxDigitsAfterDecimal",
        "Plan Price field must have 2 digits after decimal or less.",
        (number) => /^\d+(\.\d{1,2})?$/.test(number)
      ),
    total: Yup.number().required(),
    description: Yup.string().required(),
  }).required()
);

export const addPlanAndroidRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    amount: Yup.number()
      .typeError("Plan Price is required.")
      .required("Plan Price is required.")
      .test(
        "maxDigitsAfterDecimal",
        "Plan Price field must have 2 digits after decimal or less.",
        (number) => /^\d+(\.\d{1,2})?$/.test(number)
      ),
    total: Yup.number().required(),
    description: Yup.string().required(),
  }).required()
);

export const addAvailCountryRules = yupResolver(
  Yup.object({
    name: Yup.string().required(),
    isoCode: Yup.string().required(),
    phonecode: Yup.string().required(),
    currency: Yup.string().required(),
  }).required()
);

export const createNotificationRules = yupResolver(
  Yup.object({
    title: Yup.string().required(),
    content: Yup.string().required(),
  }).required()
);

export const trailRules = yupResolver(
  Yup.object({
    value: Yup.number().required(),
  }).required()
);

export const editProfileRules = yupResolver(
  Yup.object().shape({
    username: Yup.string()
      .required("Username is a required field")
      .test(
        "is-username",
        "Username can be alphanumeric",
        function (value, { createError }) {
          if (isAlphaNumeric(value)) {
            return true;
          }
          return false;
        }
      ),
    firstName: Yup.string().required("Required"),
    lastName: Yup.string().required("Required"),
    email: Yup.string().email().required("Required"),
    mobile: Yup.string()
      // .required("required")
      .test("is-mobile", "Mobile number is not US number", function (value) {
        if (!value) return true; // Allow empty value
        return isValidPhoneNumber(value, Constants.COUNTRY_CODE) === true;
      }),
    dob: Yup.date().typeError("please enter a valid date").required("Required"),
    gender: Yup.object().shape({
      label: Yup.string().required("Required"),
      value: Yup.string().required("Required"),
    }),
  })
);

export const trackPackageRules = yupResolver(
  Yup.object({
    trackingId: Yup.string().required(),
    packageName: Yup.string().required(),
    carrier: Yup.string().required(),
  }).required()
);
