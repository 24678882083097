function ShoppingBagIcon({ size = "28px", onClick, stroke }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 27 28"
      onClick={onClick}
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M8 10v3.25a3 3 0 003 3h4.714a3 3 0 003-3V10"
      ></path>
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 5.833h25v17.94a3 3 0 01-3 3H4a3 3 0 01-3-3V5.833zM4.571 1H22.43L26 5.832H1L4.571 1z"
      ></path>
    </svg>
  );
}

export default ShoppingBagIcon;
