import USER_TYPES from "../types/userTypes";

const initialFilters = Object.seal([
  {
    name: "status",
    type: "select",
    placeHolder: "Select Blocked Status",
    options: [
      {
        value: "Active",
        label: "Active",
      },
      {
        value: "Inactive",
        label: "Inactive",
      },
    ],
    value: null,
  },
  {
    name: "isSubscribed",
    type: "select",
    placeHolder: "Subscribed",
    options: [
      {
        value: "true",
        label: "True",
      },
      {
        value: "false",
        label: "False",
      },
    ],
    value: null,
  },
  {
    name: "subscriptionPlatform",
    type: "select",
    placeHolder: "Subscription Platform",
    options: [
      {
        value: "Android",
        label: "Android",
      },
      {
        value: "Ios",
        label: "Ios",
      },
    ],
    value: null,
  },
  {
    name: "subscriptionPlanType",
    type: "select",
    placeHolder: "Subscription Plan Type",
    options: [
      {
        value: "Yearly",
        label: "Yearly",
      },
      {
        value: "Monthly",
        label: "Monthly",
      },
    ],
    value: null,
  },
  {
    name: "createdAt",
    type: "daterange",
    label: "Date Added",
    value: null,
  },
]);

const initialState = {
  fetching: false,
  updating: false,
  error: null,
  list: [],
  details: null,
  goalsData: null,
  timelineData: [],
  filters: initialFilters,
  options: {},
  userReport: null,
  userGoalReport: null,
  preReportsList: [],
  reportSigned: null,
  cart: [],
  isCartOpen: false,
  isLoginModalOpen: false,
  wardrobeRoom: false,
  markingItemPurchased: false,
  markItemPurchasedError: null,
  giftListItems: [],
  profileRecommendations: [],
  loadingRecommendations: false,
  recommendationsError: null,
  recommendations: [],
  listRecommendations: {
    data: [],
    totalDocs: 0,
    loading: false,
    error: null
  }
};

export default function userReducer(state = initialState, action) {
  switch (action.type) {
    case USER_TYPES.RESET_STATE:
      return initialState;

    //your lists reducer
    case USER_TYPES.GET_YOUR_LISTS_BEGIN:
      return {
        ...state,
        // fetching: true,
        error: null,
      };

    case USER_TYPES.GET_YOUR_LISTS_SUCCESS:
      return {
        ...state,
        // fetching: false,
        error: null,
        list: action.payload,
      };

    case USER_TYPES.GET_YOUR_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Fetch Selected Category Items lists reducer
    case USER_TYPES.CATEGORY_ITEMS_SELECTED_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.CATEGORY_ITEMS_SELECTED_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.CATEGORY_ITEMS_SELECTED_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // Fetch All Product
    case USER_TYPES.FETCH_ALL_PRODUCT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.FETCH_ALL_PRODUCT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.FETCH_ALL_PRODUCT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Fetch Saved Product reducer
    case USER_TYPES.ADD_PRODUCT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.ADD_PRODUCT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.ADD_PRODUCT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

      case USER_TYPES.GET_PRODUCTS_BY_CATEGORIES_BEGIN:
        return {
          ...state,
          fetching: true,
          error: null,
        };
        
      case USER_TYPES.GET_PRODUCTS_BY_CATEGORIES_SUCCESS:
        return {
          ...state,
          fetching: false,
          error: null,
          allProducts: action.payload.docs, 
          totalProducts: action.payload.totalDocs,
        };

      case USER_TYPES.GET_PRODUCTS_BY_CATEGORIES_FAILURE:
        return {
          ...state,
          fetching: false,
          error: action.payload && action.payload.error ? action.payload.error : null,
          allProducts: [], 
          totalProducts: 0,
        };
    

    //Fetch Saved Product reducer
    case USER_TYPES.FETCH_SAVED_PRODUCT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.FETCH_SAVED_PRODUCT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.FETCH_SAVED_PRODUCT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //FETCH_PRODUCT_PURCHASE_HISTORY
    case USER_TYPES.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.FETCH_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.POST_SAVED_PRODUCT_PURCHASE_HISTORY_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.POST_SAVED_PRODUCT_PURCHASE_HISTORY_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.POST_SAVED_PRODUCT_PURCHASE_HISTORY_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //ADD_PRODUCT_TO_ANOTHER_LIST
    case USER_TYPES.ADD_PRODUCT_TO_ANOTHER_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.ADD_PRODUCT_TO_ANOTHER_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.ADD_PRODUCT_TO_ANOTHER_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_LIST_RECOMMENDATIONS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_LIST_RECOMMENDATIONS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        listRecommendations: {
          data: action.payload.docs,
          totalDocs: action.payload.totalDocs,
          loading: false,
          error: null
        }
      };

    case USER_TYPES.GET_LIST_RECOMMENDATIONS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload && action.payload.error ? action.payload.error : null,
        listRecommendations: {
          ...state.listRecommendations,
          loading: false,
          error: action.payload && action.payload.error ? action.payload.error : null
        }
      };
  

    //LIKE_PRODUCT
    case USER_TYPES.LIKE_PRODUCT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.LIKE_PRODUCT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.LIKE_PRODUCT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //DELETE_PRODUCT
    case USER_TYPES.DELETE_PRODUCT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.DELETE_PRODUCT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.DELETE_PRODUCT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Fetch Cart reducer
    case USER_TYPES.FETCH_CART:
      return {
        ...state,
        cart: action.payload,
      };

    case USER_TYPES.FETCH_CART_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_CART_SUCCESS:
      return {
        ...state,
        error: null,
        cart: action.payload,
      };

    case USER_TYPES.FETCH_CART_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Create your list reducer

    case USER_TYPES.CREATE_YOUR_LISTS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.CREATE_YOUR_LISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.CREATE_YOUR_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

      // Handle MARK_ITEM_PURCHASED actions
    case USER_TYPES.MARK_ITEM_PURCHASED_BEGIN:
      return {
        ...state,
        markingItemPurchased: true,
        error: null,
      };

    case USER_TYPES.MARK_ITEM_PURCHASED_SUCCESS:
      return {
        ...state,
        giftListItems: state.giftListItems.map(item => {
          if (item._id === action.payload.itemId) {
            return {
              ...item,
              ...action.payload.updatedData,
            };
          }
          return item;
        }),
      };

    case USER_TYPES.MARK_ITEM_PURCHASED_FAILURE:
      return {
        ...state,
        markingItemPurchased: false,
        error:
          action.payload && action.payload.error
            ? action.payload.error
            : null,
      };

    case USER_TYPES.FETCH_GIFT_LIST_BEGIN:
      return {
        ...state,
        giftListLoading: true,
        giftListError: null,
      };
    case USER_TYPES.FETCH_GIFT_LIST_SUCCESS:
      return {
        ...state,
        giftListLoading: false,
        giftList: action.payload,
      };
    case USER_TYPES.FETCH_GIFT_LIST_FAILURE:
      return {
        ...state,
        giftListLoading: false,
        giftListError: action.error,
      };


    //Update your list reducer

    case USER_TYPES.UPDATE_YOUR_LISTS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.UPDATE_YOUR_LISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.UPDATE_YOUR_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Update List Access reducer

    case USER_TYPES.UPDATE_LIST_ACCESS_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.UPDATE_LIST_ACCESS_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.UPDATE_LIST_ACCESS_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Delete your list reducer

    case USER_TYPES.DELETE_YOUR_LISTS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.DELETE_YOUR_LISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.DELETE_YOUR_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Duplicate your list reducer

    case USER_TYPES.DUPLICATE_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.DUPLICATE_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.DUPLICATE_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Like your list reducer

    case USER_TYPES.LIKE_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.LIKE_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.LIKE_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //Get Item DEtails reducer

    case USER_TYPES.GET_ITEM_DETAILS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_ITEM_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.GET_ITEM_DETAILS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        list: action.payload.data,
      };

    case USER_TYPES.GET_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_DETAILS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_DETAILS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        details: action.payload.data,
      };

    case USER_TYPES.GET_DETAILS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.UPDATE_USER_BEGIN:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case USER_TYPES.UPDATE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: null,
      };

    case USER_TYPES.UPDATE_USER_FAILURE:
      return {
        ...state,
        updating: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.DELETE_USER_BEGIN:
      return {
        ...state,
        updating: true,
        error: null,
      };

    case USER_TYPES.DELETE_USER_SUCCESS:
      return {
        ...state,
        updating: false,
        error: null,
      };

    case USER_TYPES.DELETE_USER_FAILURE:
      return {
        ...state,
        updating: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_USER_GOALS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_USER_GOALS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        goalsData: action.payload,
      };

    case USER_TYPES.GET_USER_GOALS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.CREATE_USER_REPORT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.CREATE_USER_REPORT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        userReport: action.payload,
      };

    case USER_TYPES.CREATE_USER_REPORT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.CREATE_USER_REPORT_GOAL_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.CREATE_USER_REPORT_GOAL_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        userGoalReport: action.payload,
      };

    case USER_TYPES.CREATE_USER_REPORT_GOAL_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_USER_TIMELINE_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_USER_TIMELINE_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        timelineData: action.payload,
      };

    case USER_TYPES.GET_USER_TIMELINE_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_GENERATED_REPORTS_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_GENERATED_REPORTS_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        preReportsList: action.payload,
      };

    case USER_TYPES.GET_GENERATED_REPORTS_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_REPORTS_SIGNED_URL_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_REPORTS_SIGNED_URL_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        reportSigned: action.payload,
      };

    case USER_TYPES.GET_REPORTS_SIGNED_URL_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_NOTIFICATION_SETTING_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.GET_NOTIFICATION_SETTING_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.POST_NOTIFICATION_SETTING_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.POST_NOTIFICATION_SETTING_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.POST_NOTIFICATION_SETTING_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    //wardrobe
    case USER_TYPES.POST_WARDROBE_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case USER_TYPES.POST_WARDROBE_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };
    case USER_TYPES.POST_WARDROBE_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case USER_TYPES.POST_IMAGE_WARDROBE_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case USER_TYPES.POST_IMAGE_WARDROBE_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };
    case USER_TYPES.POST_IMAGE_WARDROBE_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.UPDATE_ITEM_WARDROBE_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };
    case USER_TYPES.UPDATE_ITEM_WARDROBE_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };
    case USER_TYPES.UPDATE_ITEM_WARDROBE_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_WARDROBE_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_WARDROBE_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.GET_WARDROBE_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case USER_TYPES.GET_DETAIL_BY_ID_WARDROBE_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_DETAIL_BY_ID_WARDROBE_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.GET_DETAIL_BY_ID_WARDROBE_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.GET_PRODUCT_TAGS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_PRODUCT_TAGS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.GET_PRODUCT_TAGS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.DELETE_WARDROBE_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.DELETE_WARDROBE_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.DELETE_WARDROBE_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };
    case USER_TYPES.DELETE_WARDROBE_ITEM_BYID_OUTFIT_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.DELETE_WARDROBE_ITEM_BYID_OUTFIT_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.DELETE_WARDROBE_ITEM_BYID_OUTFIT_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // USER_EARNING REDUCERS
    case USER_TYPES.GET_USER_EARNING_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.GET_USER_EARNING_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.GET_USER_EARNING_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // USER Referral REDUCERS
    case USER_TYPES.GET_USER_REFERRAL_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.GET_USER_REFERRAL_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.GET_USER_REFERRAL_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // EXPLORE REDUCERS
    case USER_TYPES.GET_EXPLORE_TAGS_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.GET_EXPLORE_TAGS_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.GET_EXPLORE_TAGS_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // FETCH TOP_BRANDS USER REDUCERS
    case USER_TYPES.FETCH_TOP_BRANDS_USER_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_TOP_BRANDS_USER_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_TOP_BRANDS_USER_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // FETCH_TOP_BRANDS_GUEST REDUCERS
    case USER_TYPES.FETCH_TOP_BRANDS_GUEST_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_TOP_BRANDS_GUEST_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_TOP_BRANDS_GUEST_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.FETCH_RECOMMENDED_BRANDS_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
        recommendedBrands: []
      };

    case USER_TYPES.FETCH_RECOMMENDED_BRANDS_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
        recommendedBrands: action.payload.docs
      };

    case USER_TYPES.FETCH_RECOMMENDED_BRANDS_FAILURE:
      return {
        ...state,
        fetching: false,
        error: action.payload,
        recommendedBrands: []
      };

    // FETCH_FOR_YOU_USER REDUCERS
    case USER_TYPES.FETCH_FOR_YOU_USER_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_FOR_YOU_USER_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_FOR_YOU_USER_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

      case USER_TYPES.FETCH_RECOMMENDATIONS_SUCCESS:
        return {
          ...state,
          fetching: false,
          error: null,
          recommendations: action.payload.docs, 
        };

      case USER_TYPES.FETCH_RECOMMENDATIONS_FAILURE:
        return {
          ...state,
          fetching: false,
          error: action.payload || "Failed to fetch recommendations",
        };

    // FETCH_FOR_YOU_GUEST REDUCERS
    case USER_TYPES.FETCH_FOR_YOU_GUEST_BEGIN:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_FOR_YOU_GUEST_SUCCESS:
      return {
        ...state,
        error: null,
      };

    case USER_TYPES.FETCH_FOR_YOU_GUEST_FAILURE:
      return {
        ...state,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

      case USER_TYPES.GET_PROFILE_RECOMMENDATIONS_BEGIN:
        return {
          ...state,
          loadingRecommendations: true,
          recommendationsError: null,
        };
  
      case USER_TYPES.GET_PROFILE_RECOMMENDATIONS_SUCCESS:
        return {
          ...state,
          loadingRecommendations: false,
          profileRecommendations: action.payload,
          recommendationsError: null,
        };
  
      case USER_TYPES.GET_PROFILE_RECOMMENDATIONS_FAILURE:
        return {
          ...state,
          loadingRecommendations: false,
          recommendationsError: action.payload,
        };

    case USER_TYPES.GET_FOLLOW_USER_PUBLIC_LIST_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.GET_FOLLOW_USER_PUBLIC_LIST_SUCCESS:
      return {
        ...state,
        fetching: false,
        error: null,
      };

    case USER_TYPES.GET_FOLLOW_USER_PUBLIC_LIST_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    // FETCH PUBLIC/PRIVATE OUTFIT OF PROFLIE

    case USER_TYPES.FETCH_USER_PROFILE_BEGIN:
      return {
        ...state,
        fetching: true,
        error: null,
      };

    case USER_TYPES.FETCH_USER_PROFILE_SUCCESS:
      return {
        ...state,
        fetching: false,
      };

    case USER_TYPES.FETCH_USER_PROFILE_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    case USER_TYPES.SET_FILTERS:
      return {
        ...state,
        filters: action.payload,
      };

    case USER_TYPES.RESET_FILTERS:
      return {
        ...state,
        filters: initialFilters.map((item) => {
          item.value = null;
          return item;
        }),
      };
    case USER_TYPES.OPEN_CART_POPUP:
      return {
        ...state,
        isCartOpen: true,
      };
    case USER_TYPES.CLOSE_CART_POPUP:
      return {
        ...state,
        isCartOpen: false,
      };
    case USER_TYPES.OPEN_WARDROBE_ROOM_POPUP:
      return {
        ...state,
        wardrobeRoom: true,
      };
    case USER_TYPES.CLOSE_WARDROBE_ROOM_POPUP:
      return {
        ...state,
        wardrobeRoom: false,
      };

    case USER_TYPES.OPEN_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: true,
      };
    case USER_TYPES.CLOSE_LOGIN_MODAL:
      return {
        ...state,
        isLoginModalOpen: false,
      };

    default:
      return state;
  }
}

const productDetailsInitialState = {
  fetching: false,
  details: null,
  error: null,
};

export function productDetailsReducer(
  state = productDetailsInitialState,
  action
) {
  switch (action.type) {
    case USER_TYPES.FETCH_PRODUCT_DETAILS_BEGIN:
      return {
        ...state,
        fetch: true,
        details: null,
        error: null,
      };

    case USER_TYPES.GET_YOUR_LISTS_SUCCESS:
      return {
        ...state,
        fetching: false,
        details: action.payload,
      };

    case USER_TYPES.GET_YOUR_LISTS_FAILURE:
      return {
        ...state,
        fetching: false,
        error:
          action.payload && action.payload.error ? action.payload.error : null,
      };

    default:
      return state;
  }
}
