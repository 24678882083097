function EyeClosedHalfIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="17"
      fill="none"
      viewBox="0 0 27 17"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M3.853 8.725l-2.385 2.378M23.266 8.725l2.384 2.378M13.56 12.033v3.37M1 1.596a13.109 13.109 0 0025 .387"
      ></path>
    </svg>
  );
}

export default EyeClosedHalfIcon;
