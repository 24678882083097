import { useState } from "react";

function PlusIcon({ size = "18px", onClick, stroke, hoverColor }) {
  const [isHover, setIsHover] = useState(false);

  const handleHover = () => {
    setIsHover(true);
  }

  const handleUnhover = () => {
    setIsHover(false);
  }

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 20 20"
      onClick={onClick}
      cursor="pointer"
      onMouseEnter={handleHover}
      onMouseLeave={handleUnhover}
    >
      <path
        stroke={isHover ? hoverColor : stroke}
        strokeLinecap="square"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 9.994h18M10 1v18"
      ></path>
    </svg>
  );
}

export default PlusIcon;
