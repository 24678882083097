function TrashBinIcon({ width = "27", height = "30", stroke = "#fff" }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 27 30"
      cursor="pointer"
    >
      <path
        stroke={stroke}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M23.143 6H3.857v22.857h19.286V6zM17.757 1H9.236v5h8.521V1zM1 5.97h25M10.286 12.071v15.715M16.714 12.071v15.715"
      ></path>
    </svg>
  );
}

export default TrashBinIcon;
