function CloseCrossThinIcon({ onClick, fill = "#000", size = 24 }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
      onClick={onClick}
      cursor="pointer"
    >
      <path
        fill={fill}
        d="M5.923 4.744L4.744 5.923 10.822 12l-6.078 6.078 1.179 1.178L12 13.178l6.078 6.078 1.178-1.178L13.178 12l6.078-6.077-1.178-1.179L12 10.822 5.923 4.744z"
      ></path>
    </svg>
  );
}

export default CloseCrossThinIcon;
