function HeartIcon({
  onClick,
  stroke = "#fff",
  fill = "none",
  width = "24",
  height = "21",
}) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      fill="none"
      viewBox="0 0 24 21"
      onClick={onClick}
      cursor="pointer"
    >
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M20.587 1.937a5.793 5.793 0 00-7.236.88l-1.348 1.327h-.031l-1.333-1.327a5.793 5.793 0 00-7.235-.88 5.266 5.266 0 00-.88 8.126L11.934 20l.19-.19.058.058 9.3-9.805a5.266 5.266 0 00-.895-8.126v0z"
      ></path>
    </svg>
  );
}

export default HeartIcon;
