function CloseCrossIcon({ onClick }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="none"
      viewBox="0 0 11 11"
      onClick={onClick}
      cursor="pointer"
    >
      <path
        fill="currentColor"
        d="M1.64.27L5.5 4.13 9.34.29A.92.92 0 0110 0a1 1 0 01.933 1.358.9.9 0 01-.203.302L6.84 5.5l3.89 3.89A.9.9 0 0111 10a1 1 0 01-1 1 .92.92 0 01-.69-.27L5.5 6.87l-3.85 3.85A.92.92 0 011 11a1 1 0 01-.933-1.358.9.9 0 01.203-.302L4.16 5.5.27 1.61A.9.9 0 010 1a1 1 0 011-1c.24.003.47.1.64.27z"
      ></path>
    </svg>
  );
}

export default CloseCrossIcon;
