function GoogleLogoIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      fill="none"
      viewBox="0 0 24 24"
    >
      <g clipPath="url(#clip0_726_5242)">
        <path
          fill="#4285F4"
          d="M21.99 12.426c0-.839-.068-1.45-.215-2.085H12.2v3.785h5.621c-.113.94-.725 2.357-2.085 3.309l-.02.127 3.029 2.345.21.021c1.926-1.78 3.037-4.397 3.037-7.502z"
        ></path>
        <path
          fill="#34A853"
          d="M12.2 22.399c2.753 0 5.065-.907 6.753-2.47l-3.218-2.494c-.861.6-2.017 1.02-3.536 1.02-2.697 0-4.986-1.78-5.802-4.238l-.12.01-3.148 2.436-.041.115a10.192 10.192 0 009.111 5.62z"
        ></path>
        <path
          fill="#FBBC05"
          d="M6.397 14.216a6.279 6.279 0 01-.34-2.017c0-.702.125-1.382.329-2.017l-.006-.135L3.192 7.57l-.104.05A10.209 10.209 0 002 12.199c0 1.643.397 3.196 1.088 4.579l3.309-2.562z"
        ></path>
        <path
          fill="#EB4335"
          d="M12.2 5.944c1.914 0 3.206.827 3.943 1.518l2.878-2.81C17.253 3.009 14.953 2 12.2 2 8.21 2 4.765 4.29 3.088 7.62l3.298 2.562c.827-2.46 3.116-4.238 5.813-4.238z"
        ></path>
      </g>
      <defs>
        <clipPath id="clip0_726_5242">
          <path
            fill="#fff"
            d="M0 0H20V20.469H0z"
            transform="translate(2 2)"
          ></path>
        </clipPath>
      </defs>
    </svg>
  );
}

export default GoogleLogoIcon;
