function HomeIcon({ size = "24px", stroke, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12 1L1 8.283V23h7.733v-6.487h6.54V23H23V8.283L12 1z"
      ></path>
    </svg>
  );
}

export default HomeIcon;
