import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/lib/integration/react";
import { SnackbarProvider } from "notistack";
import Snackbar from "./components/Snackbar";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./redux/store";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { MsalProvider } from "@azure/msal-react";
import { Configuration, PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "./utils/azureConfig";
import { StyledMaterialDesignContent } from "./services/constants";

const msalInstance = new PublicClientApplication(msalConfig);

const app = (
  <Provider store={store}>
    <PersistGate persistor={persistor}>
      <Suspense>
        <SnackbarProvider
          maxSnack={4}
          Components={{
            success: StyledMaterialDesignContent,
            error: StyledMaterialDesignContent,
          }}
          hideIconVariant
        >
          <Snackbar />
          <GoogleOAuthProvider clientId="186959731446-df9uo2n55e1gkkiju6q4roesr898h6fe.apps.googleusercontent.com">
            <MsalProvider instance={msalInstance}>
              <App />
            </MsalProvider>
          </GoogleOAuthProvider>
        </SnackbarProvider>
      </Suspense>
    </PersistGate>
  </Provider>
);

ReactDOM.render(app, document.getElementById("root"));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
