import React from 'react';
import NoImage from "../../assets/images/no-image-1.svg";

const ImageGrid = ({ images }) => {
    if (images?.length !== 4) {
        return (
            <img
                src={NoImage}
                alt="list-cover"
                className="list-cover"
            />
        )
    }
    return (
        <div className="image-grid">
            <div className="grid-container">
                {images.map((image, index) => (
                    <div key={index} className="grid-item">
                        <img src={image} alt={`grid-item-${index}`
                        } />
                    </div>
                ))}
            </div>
        </div>
    );
}; export default ImageGrid;