import { forwardRef } from "react";

const TextArea = forwardRef(
  (
    { id, label, placeholder, onChange, onBlur, textareaClassProps, ...rest },
    ref
  ) => {
    const restProps = () => {
      const temp = { ...rest };
      temp.defaultValue = temp.value || "";
      delete temp.value;
      return temp;
    };
    return (
      <div className="d-flex flex-column" ref={ref}>
        <span>{label}</span>
        <textarea
          className={`${textareaClassProps}`}
          id={`textarea-${id}`}
          onChange={onChange}
          onBlur={onBlur}
          placeholder={placeholder}
          rows={6}
          {...restProps()}
        />
      </div>
    );
  }
);

export default TextArea;
