function SortIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="22"
      fill="none"
      viewBox="0 0 22 22"
    >
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.296 5.181L5.256 1 1.224 5.181M5.257 15.397V1.433M20.307 16.02l-4.04 4.181-4.033-4.18M16.268 5.799v13.963"
      ></path>
    </svg>
  );
}

export default SortIcon;
