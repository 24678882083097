function ClosetIcon({ size = "24px", stroke, fill }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      fill="none"
      viewBox="0 0 24 24"
    >
      <path
        stroke={stroke}
        fill={fill}
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M9.556 1H1v8.556h8.556V1zM23 1h-8.556v8.556H23V1zM23 14.444h-8.556V23H23v-8.556zM9.556 14.444H1V23h8.556v-8.556z"
      ></path>
    </svg>
  );
}

export default ClosetIcon;
