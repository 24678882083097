function FilledEyeIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="22"
      height="19"
      fill="none"
      viewBox="0 0 24 19"
    >
      <path
        fill="#fff"
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M12.003 13.076a3.906 3.906 0 100-7.811 3.906 3.906 0 000 7.81z"
      ></path>
      <path
        stroke="#fff"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        d="M1 9.318a11.536 11.536 0 0122-.494M1 9.56a11.536 11.536 0 0022-.225"
      ></path>
    </svg>
  );
}

export default FilledEyeIcon;
